import React from "react";
import { HomeBackground } from "../components/Background";
import { Body } from "../components/Body";
import { Title } from "../components/Title";
import { Divider } from "../components/Divider";
import Text from "../components/Text";
import { data } from "../data/HomePageData";
import { Seo } from "../components/Seo";

const Index = () => (
  <>
    <Seo />
    <HomeBackground />
    <Body>
      <Title text="首頁" className="text-red-hi" />
      <Divider />
      <div className="w-full my-3 px-3 md:px-6 max-w-3xl text-gray-common text-lg mb-5">
        <Text.Body className="w-full">本流程攻略包含：</Text.Body>
        <ul className="list-square list-inside w-full">
          <Text.Body className="w-full">
            <li>包含Eater線、William線在內的所有路線完整流程攻略</li>
            <li>所有CG圖像</li>
            <li>包含"We never forget you"在內的全部DLC</li>
          </Text.Body>
        </ul>
        <Text.Body className="w-full">
          <br />
          繁體中文版是在Yukie、Kankei的協助下整理製作而成的。
          <br />
          <br />
          請點擊左方的導航總覽開始使用。
          <br />
          如果有任何錯誤，錯字漏字或者建議，請通過
          <a
            href="mailto:twinklepurple1117@gmail.com?subject=[LoveUnholyc Walkthrough]"
            className="underline"
          >
            Email (English)
          </a>
          或
          <a
            href="mailto:haccayukie@gmail.com?subject=[LoveUnholyc Walkthrough]"
            className="underline"
          >
            Email (繁中)
          </a>
          聯絡我。
          <br />
          <br />
          {"祝遊戲愉快：）"}
          <br />
          <br />
          <br />
          <span className="text-xl">PrettyBusy 官方網站:</span>
          <br />
        </Text.Body>
        <ul className="list-disc list-inside underline">
          <Text.Body>
            {data.map(({ en, link }) => (
              <li key={link} className="my-1">
                <a href={link}>{en}</a>
              </li>
            ))}
          </Text.Body>
        </ul>
      </div>
    </Body>
  </>
);

export default Index;
